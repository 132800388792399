.section-wrapper {
  /* display: flex; */
  width: 100%;
  background-color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  /* justify-content: center; */
  /* align-items: center;  */
  /* overflow-x: scroll; */
  /* padding: 10px; */
}
