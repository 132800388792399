.project-item {
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  border-radius: 10px;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px;
  cursor: pointer;
}
