section {
    display: flex;

    /* animation: swipeRight var(--speed) linear infinite; */
    animation: var(--direction) var(--speed) linear infinite;
    margin: 15px;
    width: 100%;
    gap: 10px;
  }

  section:hover {
    animation-play-state: paused;
  }

  @keyframes swipeUp {
    0% {
      transform: translateY(0);
    }
  
    100% {
      transform: translateY(-100%);
    }
  }

  @keyframes swipeLeft {
    0% {
      transform: translate(0);
    }
  
    100% {
      transform: translate(-100%);
    }
  }

  @keyframes swipeRight {
    100% {
      transform: translate(0);
    }
  
    0% {
      transform: translate(-100%);
    }
  }
  