.home-intro {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  min-height: 600px;
  max-height: 'auto';
  width: 100%;
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;
  gap:15px;
  background: linear-gradient(to right, #232327, #160129)
  /* background: url('https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fstatic.vecteezy.com%2Fsystem%2Fresources%2Fpreviews%2F000%2F664%2F710%2Foriginal%2Fvector-abstract-technology-background-hi-tech-communication-digital-background.jpg&f=1&nofb=1&ipt=cac9c77744a76def44bdc193fcef1dc1efedb4bec389d92629ef6ad88593dd18&ipo=images') */
    center / cover no-repeat;
  /* center / contain no-repeat */
}

.intro-link {
  text-decoration: underline;
  font-size: medium;
  margin-inline: 10px;
}
.intro-link:hover {
  color: purple;
  cursor: pointer;
}

.home-intro-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-intro h1 {
  /* border: 1px solid black; */
  /* margin:10px */
  padding: 10px;
}
.home-intro h2 {
  margin-top: -30px;
  margin-bottom: 10px;
  /* width: 200px; */
}

@media (max-width: 600px) {
  .home-intro {
    /* visibility:; */
    min-height: 600px;
    text-align: center;
    /* visibility: hidden; */
  }
}
