.pop-from-bottom {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* transform: skew(10deg,10deg); */

  /* animation: 2s 1s pop-from-bottom; */
  animation: 3s pop-from-bottom;
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  /* animation: pop-from-bottom 1s timing-function 1s iteration-count direction fill-mode; */
  /* animation-delay: 2s; */
  /* animation-duration: 3s; */
  /* animation: 2s rollout; */
  
}
.pop-from-bottom-before {
  visibility: hidden; 
}

/* The animation code */
@keyframes pop-from-bottom {
  0% {
    left: 0px;
    top: 300px;
    /* bottom: 300px; */
  }
  25% {
    left: 0px;
    top: 0px;
  }
  50% {
    left: 0px;
    top: 40px;
  }
  75% {
    left: 0px;
    top: 0px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}
@keyframes rollout {
  0% {
    transform: translateY(200px);
  }
  100% {
    transform: translateY(0);
  }
}
