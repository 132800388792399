html {
    font-size: 16px;
  }
  
  body {
    font-size: 1rem; /* 16px */
  }
  
  
  ul{
    display: flex;
    flex-direction: column;
    gap:15px;
  }

li {
  font-size: 20px;
}

  h1 {
    font-size:50px
  }
  
  h2 {
    font-size: 40px
  }
  
  h3 {
    font-size:30px
    
  }
  
  h4 {
    font-size: 1.125rem; /* 18px */
  }
  
  h5 {
    font-size: 1rem; /* 16px */
  }
  
  h6 {
    font-size: 0.875rem; /* 14px */
  }
  
  p {
    font-size: 1.5rem; /* 16px */
  }
  
  small {
    font-size: 0.875rem; /* 14px */
  }
  
  button,
  input {
    font-size: 1rem; /* 16px */
  }
  
  .sidebar-item {
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .sidebar-item.highlight {
    background-color: #3a3f47; /* Highlight color */
  }
  
  .sidebar-item:hover {
    background-color: #3a3f47; /* Hover color */
  }
  
  .main{
    margin-inline-start: 20%;
  }

  @media( max-width:600px){
    .side{
      margin-inline-start: 0%;
      visibility: hidden;
      
    }
    .main{
      margin-inline-start:0% ;
      /* marginInlineStart: '20%', // Account for fixed sidebar width */

    }
    h1 {
      font-size:30px
    }
  }