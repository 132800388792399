.flip-card {
  background-color: transparent;
  width: 300px;
  height: 200px;
  perspective: 1000px;
  cursor: pointer;
  /* margin-top: 50vh; */
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 2s;
  transform-style: preserve-3d;
}

.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: white;
}

.flip-card-front {
  background-color:black
  /* background-color: #e74c3c; */
}

.flip-card-back {
    /* background-color: white; */
    background-color: #2980b9;

  transform: rotateY(180deg);
}

@media (max-width: 600px) {
  .flip {
    visibility: visible;
    display: block;
  }
}
