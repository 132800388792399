.layout {
  /* position: relative; */
  position: absolute;
  /* width: 100%; */
  top: 0;
  left: 0;
  right: 0;
}
.body {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  scrollbar-width: none;
  overflow: hidden;
}

.header {
  /* position: fixed; */
  position: sticky;
  /* right: 0;
  left: 0; */
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-content: space-around;
  z-index: 99999;
  max-height: 60px;
  padding-inline: 5px;
  background-color: black;
}
.buttons {
  display: flex;
  /* border: 1px solid white; */
  width: 100%;
  /* justify-content: space-evenly; */
  /* flex-wrap: wrap; */
}
.footer {
  position: fixed;
  display: flex;
  right: 0;
  left: 0;
  bottom: 0;
  /* background-color: black; */
}

.hamburger {
  visibility: hidden;
  color: white;
  display: flex;
  rotate: 90deg;
  justify-content: center;
  align-items: center;
}
/* Media query for smaller screens */
@media (max-width: 600px) {
  .header {
    /* flex-direction: column; */
    /* justify-content: space-around; */
    justify-content: space-between;
    align-items: center;
    padding-inline: 15px;
  }
  .hamburger {
    visibility: visible;
  }
  .buttons {
    display: none;
  }
  .nav {
    flex-direction: column;
    width: 100%;
  }

  .nav a {
    width: 100%;
    text-align: left;
  }

  .logo {
    align-self: flex-end;
    margin-top: 1rem;
  }

  .body {
    /* padding: 1rem; */
  }
}
