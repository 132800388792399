/* @font-face {
  font-family: 'Almoni';
  src: local('NunitoSans-Regular.ttf'),
    url('../../assets/fonts/Almoni\ ML\ v5\ AAA\ Light.ttf') format('truetype');
} */

.card {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  /* background-color: white; */
  background-color:#ADB5BD;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.children_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
}
.card_container {
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: flex-start; */
  /* border: 1px solid black; */
}
/* .almoni {
  font-family: 'Almoni';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
} */

@media (max-width: 600px) {

.card{
  min-width: 150;
}

}