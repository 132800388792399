.card-image {
  /* height: 200px; */
  height: 300px;
  max-width: 500;
  /* min-width: 200px; */
  width: 100%;
}

  @media (max-width: 600px) {
 
    .card-image {
      /* height: auto;    */
      height: '50%';
      min-width: 250px;
      width: 100%;
    }
  }