.guide-card {
  height: 400;
  width: 500;
  /* min-height: 600px; */
  /* border: 1px solid black; */
}

.guide-image {
  /* height: 200px; */
  height: 300px;
  max-width: 500;
  /* min-width: 200px; */
  width: 100%;
}

.card-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap:15;
}

@media (max-width: 600px) {
  .guide-card {
    min-height: 150px;
    min-width: 100px;
  }
  .guide-image {
    /* height: auto;    */
    height: '50%';
    min-width: 250px;
    width: 100%;
  }
}
