.animated {
  position: relative;
  animation-duration: 4s;
  direction: rtl;
  overflow: hidden;
  /* display: flex; */
  cursor: pointer;
  /* justify-content: space-between; */
  align-items: center;
  /* gap: 10; */
  padding: 10px;
  box-shadow: 4px 4px 4px 4px darkgray;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 500;

  /* border: 1px solid black; */
}
h2 {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.children {
  display: flex;
  flex-direction: column;
  direction: ltr;
  text-align: center;
  background-color: #adb5bd;
}

.img-container {
  display: flex;
  height: 400px;
  width: 500px;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
}
.img {
  width: 100%;
  height: 100%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pop-from-side {
  0% {
    right: 0%;
    top: 50%;
    bottom: 50%;
  }
  /* 25% {
      right: 50px;
      top: 50%;
    }
    50% {
      right: 100px;
      top: 50%;
    }
    75% {
      right: 400px;
      top: 50%;
    } */
  100% {
    right: 50%;
    top: 50%;
  }
}

.animatedHover {
  position: absolute;
  left: 20px;
  right: 20px;
  /* bottom: 40px; */
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 40px;
  animation: fadeIn 1s;
  /* animation: pop-from-side 2s; */
}

.animated:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0.8;
  background-color: #3783dd;
  overflow: hidden;
  width: 0;
  height: 100%;
  content: '';
  transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  cursor: pointer;
}

.animated:hover:after {
  width: 100%;
}

@media (max-width: 600px) {
  .img {
    /* width: 100%; */
    /* height: 150px; */
    /* width: 150px; */
    min-height: 50px;
    min-width: 150px;
  }
  .img-container {
display: flex;
    min-height: 50px;
    height: 100%;
    width: 100%;
  }
}
