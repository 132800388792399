.scroll-up-button {
  position: fixed;
  /* display: flex; */
  bottom: 5%;
  right:5%;
  /* left: 95%; */
  z-index: 100000;
  cursor: pointer;
  /* color: green; */
  font-size: 50px;
  font-weight: bold;

  /* transform: rotate(-90deg) translate(-50%); */
}
@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}
.scroll-up-button:hover {
  color: green;
  /* background-color: blue; */
}

.scroll {
  font-size: 50px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: blue;
  transform: rotate(-90deg);
}
