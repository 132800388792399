.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  overflow-wrap: break-word;
  line-height: 1.5em;
  /* min-height: 1.5rem; */
  max-height: 1.5rem;
  position: relative;
  text-align: center;
  /* border: 1px solid black; */
  /* direction: inherit; */
}

.ellipsis::after {
  content: '...';
  background-color: inherit;
}

.multi-line-text {
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;

  border: 1px solid #ddd;
  overflow: hidden; /* Hide overflow */
  position: relative; /* Required for pseudo-element positioning */
}

.multi-line-text::after {
  content: '...';
  position: absolute;
  bottom: 0;
}
