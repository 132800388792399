.auto-scroll-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* border: 1px solid black; */
  justify-content: center;
  align-items: center;
  padding: 15px;
}
.auto-scroll {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--width);
  height: var(--height);
  gap: 15px;
  animation: swipeUp var(--speed) linear infinite;
}

.auto-scroll:hover {
  animation-play-state: paused;
}

@keyframes swipeUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}

@media (max-width: 600px) {
 .auto-scroll{
  width: 100%;
 }
}



