.header-button {
  color: white;
  border-radius: 15px;
  font-family: 'Inter';
  font-size: 20px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
}
.header-button-checked {
  border: 2px solid blue;
  border-radius: 15px;
}
.header-button:hover {
  text-decoration: underline;
}
.header-button-drop {
  position: absolute;
  /* width: 200px; */
  flex-direction: column;
  background-color: #bcbcbc;
  border-radius: 15px;
  border: 1px solid black;
  padding: 5px;
  margin-top: 10px;
  justify-content: center;
}
