.profession-grid {
  display: flex;
  justify-content:center;
  align-items: center;
  padding: 15px;
  /* border: 1px solid black; */
}
.cards-grid {
  display: flex;
  /* justify-content:space-evenly; */
  justify-content:center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  /* width: 90%; */
  /* overflow:hidden; */
  /* border: 1px solid black; */
}
.grid-image{
  /* max-height: 500px; */
  /* height: 500px; */
  /* width: 100%; */
  border: 4px solid cyan;
  height: 600px;
  width: 800px;
}

@media (max-width: 600px) {
  .grid-image{
    /* width: 100%; */
    display: none;
  }
}
