.paging-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}
.paging-table {
  display: flex;
  flex-direction: column;
  padding: 15px;
  height: 70vh;
  width: 60vw;
  overflow-x: hidden;
  gap: 15px;
}
.pagination-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pagination-item {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
  border: 1px solid black;
  cursor: pointer;
}
.checked {
  /* border: 1px solid blue; */
  background-color: rgba(255, 0, 0, 0.541);
}
.disabled {
  display: none;
}
.arrow {
  position: relative;
  /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
  content: '';
  /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}
.arrow-right {
  position: relative;
  /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
  content: '';
  /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
  transform: rotate(45deg);
}
.arrow-left {
  position: relative;
  /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
  content: '';
  /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
  transform: rotate(-135deg) translate(-50%);
}
.arrow-disabled {
  pointer-events: none;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 20px;
}
