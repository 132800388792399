.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  /* margin-top: 30px; */
  /* border: 1px solid black; */
}
.intro {
  /* height: 90px; */
  margin: 10px;
  padding: 10px;
  border-radius: 0.25rem;
  border: 1px solid black;
}
.details {
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  border-radius: 0.25rem;
  border: 1px solid black;
  text-align: center;
}
.code-snippet {
  background-color: black;
  height: 300px;
  width: 300px;
  color: white;
  padding: 10px;
}
div#info p {
  text-indent: 10px;
}
